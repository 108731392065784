/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { mq } from "../../utils/themeUtils"
import BackgroundImage from "gatsby-background-image/index"
import { graphql, useStaticQuery } from "gatsby"
import { useTheme } from "emotion-theming"
import { IAppTheme } from "../../theme"
import { rgba } from "polished"
import { tsParticles } from "tsparticles"
import { useEffect } from "react"

const makeStyles = () => ({
  root: css`
    height: 350px;
    width: 100%;
    position: relative;

    ${mq.minWidth.lg} {
      height: 500px;
    }

    & > .inner {
      position: relative;
      height: 100%;
    }

    & > .particles-js-canvas-el {
      position: absolute;
      top: 0;
      left: 0;
    }
  `,
  particlesDiv: css`
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  `,
  background: css`
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  `,
})

const FPHeroHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "frontPage/da-nang-1031403_1920.jpg" }) {
        ...FHDImage_withWebp
      }
    }
  `)

  const background = data.file.childImageSharp.fluid
  const theme = useTheme<IAppTheme>()
  const styles = makeStyles()

  useEffect(() => {
    tsParticles.load("particlesDiv", {
      particles: {
        number: {
          value: 80,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 3,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 7,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "repulse",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    })
  }, [])

  return (
    <section css={styles.root}>
      <div id="particlesDiv" css={styles.particlesDiv} />
      <BackgroundImage
        css={styles.background}
        fluid={[
          `linear-gradient(
            to bottom,
            ${rgba(theme.color.primary, 0.2)},
            ${rgba(theme.color.secondary, 0.2)}
          )`,
          background,
        ]}
      />
    </section>
  )
}

export default FPHeroHeader
